export default {
  // Navbar
  pageTitle: '',
  breadcrumbs: [],

  // Sidebar
  sidebarMiniVariant: true,
  drawerState: true,
  formItems: {},

  // Snackbar
  snackbar: {
    visible: false,
    text: null,
    timeout: 3000,
    color: '',
    multiline: false
  },
  
  // Loading
  loading: {
    visible: false,
    text: null,
    color: 'white',
    width: 0.4
  }
}