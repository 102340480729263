import Vue from 'vue'
import Router from 'vue-router'

import AuthService from '@/services/Auth'

import store from '@/store'

Vue.use(Router)


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/pages/Main/Main.vue'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          meta: {
            title: 'Dashboard'
          },
          component: () => import('@/pages/Dashboard/Dashboard.vue')
        },
        {
          path: '/request-goods',
          name: 'requestGoods',
          meta: {
            title: 'Request Goods',
            breadcrumb: {
              text: 'Request Goods'
            }
          },
          component: () => import('@/pages/RequestGoods/RequestGoods/RequestGoods.vue')
        },
        {
          path: '/request-goods/response/:id',
          name: 'requestGoodsResponse',
          meta: {
            title: 'Request Goods - Response',
            breadcrumb: [
              {
                text: 'Request Goods',
                path: '/request-goods'
              },
              {
                text: 'Response'
              }
            ]
          },
          component: () => import('@/pages/RequestGoods/RequestGoodsResponse/RequestGoodsResponse.vue')
        },
        {
          path: '/suppliers',
          name: 'suppliers',
          meta: {
            title: 'Suppliers',
            breadcrumb: {
              text: 'Suppliers'
            }
          },
          component: () => import('@/pages/Suppliers/Suppliers/Suppliers.vue')
        },
        {
          path: 'suppliers/add',
          name: 'addSupplier',
          meta: {
            title: 'Add Supplier',
            breadcrumb: [
              {
                text: 'Suppliers',
                path: '/suppliers'
              },
              {
                text: 'Add Supplier'
              }
            ]
          },
          component: () => import('@/pages/Suppliers/AddSupplier/AddSupplier.vue')
        },
        {
          path: 'suppliers/:id/details',
          name: 'supplierDetails',
          meta: {
            title: 'Supplier Details',
            breadcrumb: [
              {
                text: 'Suppliers',
                path: '/suppliers'
              },
              {
                text: 'Supplier Details'
              }
            ]
          },
          component: () => import('@/pages/Suppliers/SupplierDetails/SupplierDetails.vue')
        },
        {
          path: '/brands',
          name: 'brands',
          meta: {
            title: 'Brands',
            breadcrumb: {
              text: 'Brands'
            }
          },
          component: () => import('@/pages/Brands/Brands.vue')
        },
        {
          path: '/warehouses',
          name: 'warehouses',
          meta: {
            title: 'Warehouses',
            breadcrumb: {
              text: 'Warehouses'
            }
          },
          component: () => import('@/pages/Warehouses/Warehouses/Warehouses.vue')
        },
        {
          path: '/import-wizard', //Ganti title aja
          name: 'import-wizard',
          meta: {
            title: 'Category Mapping',
            breadcrumb: {
              text: 'Category Mapping'
            }
          },
          component: () => import('@/pages/ImportWizard/ImportWizard/ImportWizard.vue')
        },
        {
          path: '/manage-commission',
          name: 'manage-commission',
          meta: {
            title: 'Manage Commission',
            breadcrumb: {
              text: 'Manage Commission'
            }
          },
          component: () => import('@/pages/ManageCommission/Index/ManageCommissionIndex.vue')
        },
        {
          path: '/manage-agent',
          name: 'manage-agent',
          meta: {
            title: 'Manage Agent',
            breadcrumb: {
              text: 'Manage Agent'
            }
          },
          component: () => import('@/pages/ManageAgent/Index/ManageAgentIndex.vue')
        },
        {
          path: '/manage-agent/:id/details',
          name: 'agentDetails',
          meta: {
            title: 'Agent Details',
            breadcrumb: [
              {
                text: 'Manage Agent',
                path: '/manage-agent'
              },
              {
                text: 'Agent Details'
              }
            ]
          },
          component: () => import('@/pages/ManageAgent/Details/ManageAgentDetails.vue')
        },
        {
          path: '/manage-partner',
          name: 'manage-pertner',
          meta: {
            title: 'Manage Partner',
            breadcrumb: {
              text: 'Manage Partner'
            }
          },
          component: () => import('@/pages/ManagePartner/Index/ManagePartnerIndex.vue')
        },
        {
          path: '/manage-partner/:id/details',
          name: 'partnerDetails',
          meta: {
            title: 'Partner Details',
            breadcrumb: [
              {
                text: 'Manage Partner',
                path: '/manage-partner'
              },
              {
                text: 'Partner Details'
              }
            ]
          },
          component: () => import('@/pages/ManagePartner/Details/ManagePartnerDetails.vue')
        },
        {
          path: '/orders',
          name: 'orders',
          meta: {
            title: 'Orders',
            breadcrumb: {
              text: 'Orders Lists'
            }
          },
          component: () => import('@/pages/Orders/Orders/Orders.vue')
        },
        {
          path: '/orders/create',
          name: 'CreateOrder',
          meta: {
            title: 'Create Order',
            breadcrumb: [
              // {
              //   text: 'Orders',
              //   path: '/orders'
              // },
              {
                text: 'Create Order'
              }
            ]
          },
          component: () => import('@/pages/Orders/CreateOrder/CreateOrder.vue')
        },
        {
          path: '/orders/createOrderNonCatalog',
          name: 'CreateOrderNonCatalog',
          meta: {
            title: 'Create Order Non-Catalog',
            breadcrumb: [
              // {
              //   text: 'Orders',
              //   path: '/orders'
              // },
              {
                text: 'Create Order Non-Catalog'
              }
            ]
          },
          component: () => import('@/pages/Orders/CreateOrderNonCatalog/CreateOrderNonCatalog.vue')
        },
        {
          path: '/orders/:id/details',
          name: 'orderDetails',
          meta: {
            title: 'Order Details',
            breadcrumb: [
              {
                text: 'Orders',
                path: '/orders'
              },
              {
                text: 'Order Details'
              }
            ]
          },
          component: () => import('@/pages/Orders/OrderDetails/OrderDetails.vue')
        },
        {
          path: '/orders/:id/invoices',
          name: 'orderInvoices',
          meta: {
            title: 'Order Invoices',
            breadcrumb: [
              {
                text: 'Orders',
                path: '/orders'
              },
              {
                text: 'Order Invoices'
              }
            ]
          },
          component: () => import('@/pages/Orders/OrderInvoices/OrderInvoices.vue')
        },
        {
          path: '/invoices',
          name: 'invoices',
          meta: {
            title: 'Invoices',
            breadcrumb: {
              text: 'Invoices'
            }
          },
          component: () => import('@/pages/Orders/Invoices/Invoices.vue')
        },
        {
          path: '/shipment-courier',
          name: 'shipmentCourier',
          meta: {
            title: 'Shipment Courier',
            breadcrumb: {
              text: 'Shipment Courier'
            }
          },
          component: () => import('@/pages/Shipments/ShipmentCourier/ShipmentRequestIndex/ShipmentRequestIndex.vue')
        },
        {
          path: '/shipment-acn',
          name: 'shipmentACN',
          meta: {
            title: 'Shipment by ACN',
            breadcrumb: {
              text: 'Shipment by ACN'
            }
          },
          component: () => import('@/pages/Shipments/ShipmentACN/Index/ShipmentListIndex.vue')
        },
        {
          path: '/shipment-acn/:id/edit',
          name: 'shipmentACN-edit',
          meta: {
            title: 'Edit Shipment by ACN',
            breadcrumb: [
              {
                text: 'Shipment ACN',
                path: '/shipment-acn'
              },
              {
                text: 'Edit Shipment by ACN'
              }
            ]
          },
          component: () => import('@/pages/Shipments/ShipmentACN/Edit/ShipmentEdit.vue')
        },
        {
          path: '/shipment-acn/create',
          name: 'shipmentACNCreate',
          meta: {
            title: 'Create New Shipment',
            breadcrumb: [
              {
                text: 'Shipment ACN',
                path: '/shipment-acn'
              },
              {
                text: 'Create New Shipment'
              }
            ]
          },
          component: () => import('@/pages/Shipments/ShipmentACN/Create/ShipmentListCreate.vue')
        },
        {
          path: '/products',
          name: 'products',
          meta: {
            title: 'Products',
            breadcrumb: {
              text: 'Products Lists'
            }
          },
          component: () => import('@/pages/Products/Products/Products.vue')
        },
        {
          path: '/products/create',
          name: 'products.create',
          meta: {
            title: 'Create Product',
            breadcrumb: [
              // {
              //   text: 'Products',
              //   path: '/products'
              // },
              {
                text: 'Create Product'
              }
            ]
          },
          component: () => import('@/pages/Products/CreateProduct/CreateProduct.vue')
        },
        {
          path: '/products/:id/edit',
          name: 'products.edit',
          meta: {
            title: 'Edit Product',
            breadcrumb: [
              {
                text: 'Products',
                path: '/products'
              },
              {
                text: 'Edit Product'
              }
            ]
          },
          component: () => import('@/pages/Products/EditProduct/EditProduct.vue')
        },
        // {
        //   path: '/warehouses',
        //   name: 'warehouses',
        //   meta: {
        //     title: 'Warehouses',
        //     breadcrumb: {
        //       text: 'Warehouses'
        //     }
        //   },
        //   component: () => import('@/pages/Warehouses/Warehouses/Warehouses.vue')
        // },
        // {
        //   path: '/inbounds',
        //   name: 'inbounds',
        //   meta: {
        //     title: 'Inbounds',
        //     breadcrumb: {
        //       text: 'Inbounds'
        //     }
        //   },
        //   component: () => import('@/pages/Warehouses/Inbounds/Inbounds/Inbounds.vue')
        // },
        // {
        //   path: '/inbounds/create',
        //   name: 'inbounds.create',
        //   meta: {
        //     title: 'Create Inbound',
        //     breadcrumb: [
        //       {
        //         text: 'Inbounds',
        //         path: '/inbounds'
        //       },
        //       {
        //         text: 'Create Inbound'
        //       }
        //     ]
        //   },
        //   component: () => import('@/pages/Warehouses/Inbounds/CreateInbound/CreateInbound.vue')
        // },
        // {
        //   path: '/inbounds/create/make/:id',
        //   name: 'inbounds.make',
        //   meta: {
        //     title: 'Make Inbound',
        //     breadcrumb: [
        //       {
        //         text: 'Inbounds',
        //         path: '/inbounds'
        //       },
        //       {
        //         text: 'Create Inbound',
        //         path: '/inbounds/create'
        //       },
        //       {
        //         text: 'Make Inbound'
        //       }
        //     ]
        //   },
        //   component: () => import('@/pages/Warehouses/Inbounds/MakeInbound/MakeInbound.vue')
        // },
        // {
        //   path: '/inbounds/create/newUserInbound',
        //   name: 'inbounds.create.newUserInbound',
        //   meta: {
        //     title: 'Make Offline Inbound From New User',
        //     breadcrumb: [
        //       {
        //         text: 'Inbounds',
        //         path: '/inbounds'
        //       },
        //       {
        //         text: 'Create Inbound',
        //         path: '/inbounds/create'
        //       },
        //       {
        //         text: 'Make Offline Inbound From New User'
        //       }
        //     ]
        //   },
        //   component: () => import('@/pages/Warehouses/Inbounds/OfflineInbounds/NewUserInbound/NewUserInbound.vue')
        // },
        // {
        //   path: '/inbounds/create/existingUserInbound',
        //   name: 'inbounds.create.existingUserInbound',
        //   meta: {
        //     title: 'Make Offline Inbound From Existing User',
        //     breadcrumb: [
        //       {
        //         text: 'Inbounds',
        //         path: '/inbounds'
        //       },
        //       {
        //         text: 'Create Inbound',
        //         path: '/inbounds/create'
        //       },
        //       {
        //         text: 'Make Offline Inbound From Existing User'
        //       }
        //     ]
        //   },
        //   component: () =>
        //     import('@/pages/Warehouses/Inbounds/OfflineInbounds/ExistingUserInbound/ExistingUserInbound.vue')
        // },
        // {
        //   path: '/billings',
        //   name: 'billings',
        //   meta: {
        //     title: 'Billings',
        //     breadcrumb: [
        //       {
        //         text: 'Billings',
        //         path: '/billings'
        //       }
        //     ]
        //   },
        //   component: () => import('@/pages/Billings/BillingIndex/Billing.vue')
        // },
        // {
        //   path: '/billings/:id/detail',
        //   name: 'BillingDetail',
        //   meta: {
        //     title: 'Billings',
        //     breadcrumb: [
        //       {
        //         text: 'Billings',
        //         path: '/billings'
        //       },
        //       {
        //         text: 'Billing Detail'
        //       }
        //     ]
        //   },
        //   component: () => import('@/pages/Billings/BillingDetail/BillingDetail.vue')
        // },
        // {
        //   path: '/quality-control',
        //   name: 'qualityControl.index',
        //   meta: {
        //     title: 'Quality Control',
        //     breadcrumb: {
        //       text: 'Quality Control'
        //     }
        //   },
        //   component: () => import('@/pages/QualityControl/Index/QcIndex.vue')
        // },
        // {
        //   path: '/quality-control/:id/detail',
        //   name: 'Quality Control',
        //   meta: {
        //     title: 'Quality Control Detail',
        //     breadcrumb: [
        //       {
        //         text: 'Quality Control',
        //         path: '/quality-control'
        //       },
        //       {
        //         text: 'Quality Control Detail'
        //       }
        //     ]
        //   },
        //   component: () => import('@/pages/QualityControl/Detail/QcDetail.vue')
        // },
        {
          path: '/promo-deals',
          name: 'PromoDeals',
          meta: {
            title: 'Promo Deals',
            breadcrumb: {
              text: 'Promo Deals'
            }
          },
          component: () => import('@/pages/Promotion/PromoDeals/promoDeals.vue')
        },
        {
          path: '/banner',
          name: 'Banner',
          meta: {
            title: 'Banner Mobile',
            breadcrumb: {
              text: 'Banner Mobile'
            }
          },
          component: () => import('@/pages/Promotion/Banner/banner.vue')
        },
        {
          path: '/custom-menu',
          name: 'customMobile',
          meta: {
            title: 'Custom Menu Mobile',
            breadcrumb: {
              text: 'Custom Menu Mobile'
            }
          },
          component: () => import('@/pages/Promotion/CustomMobile/customMobile.vue')
        },
        {
          path: '/notifications',
          name: 'Notifications',
          meta: {
            title: 'Notifications',
            breadcrumb: {
              text: 'Notifications'
            }
          },
          component: () => import('@/pages/Promotion/Notifications/NotificationsIndex.vue')
        },
        {
          path: '/manage-user',
          name: 'manageUser.index',
          meta: {
            title: 'Manage User',
            breadcrumb: {
              text: 'Manage User'
            }
          },
          component: () => import('@/pages/UserSettings/Index/UserSettingsIndex.vue')
        },
        {
          path: '/manage-user/:id/members-partner',
          name: 'membersPartner',
          meta: {
            title: 'User Member Relation',
            breadcrumb: [
              {
                text: 'Manage User',
                path: '/manage-user'
              },
              {
                text: 'User Member Relation'
              }
            ]
          },
          component: () => import('@/pages/UserSettings/MembersPartner/MembersPartner.vue')
        },
        {
          path: '/manage-user/:id/balance-history',
          name: 'balanceHistory',
          meta: {
            title: 'Balance History',
            breadcrumb: [
              {
                text: 'Manage User',
                path: '/manage-user'
              },
              {
                text: 'Balance History'
              }
            ]
          },
          component: () => import('@/pages/UserSettings/BalanceHistory/BalanceHistory.vue')
        },
        {
          path: '/manage-user/:id/details',
          name: 'userDetails',
          meta: {
            title: 'User Details',
            breadcrumb: [
              {
                text: 'Manage User',
                path: '/manage-user'
              },
              {
                text: 'User Details'
              }
            ]
          },
          component: () => import('@/pages/UserSettings/UserDetails/UserDetails.vue')
        },
        {
          path: '/manage-limitation',
          name: 'manage-limitation',
          meta: {
            title: 'Manage Limitation',
            breadcrumb: {
              text: 'Manage Limitation'
            }
          },
          component: () => import('@/pages/ManageLimitation/manageLimitation.vue')
        },
        {
          path: '/manage-referral',
          name: 'manage-referral',
          meta: {
            title: 'Manage Referral',
            breadcrumb: {
              text: 'Manage Referral'
            }
          },
          component: () => import('@/pages/ManageReferral/Index/manageReferral.vue')
        },
        {
          path: '/area-lists',
          name: 'area-list',
          meta: {
            title: 'Area List',
            breadcrumb: {
              text: 'Area List'
            }
          },
          component: () => import('@/pages/Others/AreaList/areaList.vue')
        },
        {
          path: '/port-lists',
          name: 'port-list',
          meta: {
            title: 'Port List',
            breadcrumb: {
              text: 'Port List'
            }
          },
          component: () => import('@/pages/Others/PortList/Index/PortListIndex.vue')
        },
        {
          path: '/currencies',
          name: 'currencies',
          meta: {
            title: 'Currencies',
            breadcrumb: {
              text: 'Currencies'
            }
          },
          component: () => import('@/pages/Others/Currency/Index/CurrencyIndex.vue')
        },
        {
          path: '/province-list',
          name: 'province-list',
          meta: {
            title: 'Province List',
            breadcrumb: {
              text: 'Province List'
            }
          },
          component: () => import('@/pages/Others/ProvinceList/Index/ProvinceListIndex.vue')
        },
        {
          path: '/city-list',
          name: 'city-list',
          meta: {
            title: 'City List',
            breadcrumb: {
              text: 'City List'
            }
          },
          component: () => import('@/pages/Others/CityList/Index/CityListIndex.vue')
        },
        {
          path: '/country-list',
          name: 'country-list',
          meta: {
            title: 'Country List',
            breadcrumb: {
              text: 'Country List'
            }
          },
          component: () => import('@/pages/Others/CountryList/Index/CountryListIndex.vue')
        },
        {
          path: '/hscodes',
          name: 'hscodes',
          meta: {
            title: 'HS Codes',
            breadcrumb: {
              text: 'HS Codes'
            }
          },
          component: () => import('@/pages/Others/Hscodes/Index/HscodesIndex.vue')
        },
        {
          path: '/review-list',
          name: 'review-list',
          meta: {
            title: 'Review List',
            breadcrumb: {
              text: 'Review List'
            }
          },
          component: () => import('@/pages/Others/ReviewList/Index/ReviewListIndex.vue')
        },
        {
          path: '/ali-2-international-shipping',
          name: 'ali2InternationalShipping',
          meta: {
            title: 'International Shipping Rate',
            breadcrumb: {
              text: 'International Shipping Rate'
            }
          },
          component: () => import('@/pages/Ali2Settings/Logistic/LogisticIndex.vue')
        },
        {
          path: '/ali-2-domestic-shipping',
          name: 'ali2DomesticShipping',
          meta: {
            title: 'Domestic Shipping Rate',
            breadcrumb: {
              text: 'Domestic Shipping Rate'
            }
          },
          component: () => import('@/pages/Ali2Settings/DomesticShipping/DomesticShipping.vue')
        },
        {
          path: '/ali-2-category-rules',
          name: 'ali2CategoryRules',
          meta: {
            title: 'Category Rules',
            breadcrumb: {
              text: 'Category Rules'
            }
          },
          component: () => import('@/pages/Ali2Settings/CategoryRules/CategoryRulesIndex.vue')
        },
        {
          path: '/ali-2-product-rules',
          name: 'ali2ProductRules',
          meta: {
            title: 'Product Rules',
            breadcrumb: {
              text: 'Product Rules'
            }
          },
          component: () => import('@/pages/Ali2Settings/ProductRules/ProductRulesIndex.vue')
        },
        {
          path: '/ali-2-manage-category',
          name: 'ali2ManageCategory',
          meta: {
            title: 'Manage Category',
            breadcrumb: {
              text: 'Manage Category'
            }
          },
          component: () => import('@/pages/Ali2Settings/ManageCategory/manageCategoryIndex.vue')
        },
        {
          path: '/ali-2-payment-settings',
          name: 'ali2PaymentSettings',
          meta: {
            title: 'ALI-2 Settings - Payment Settings',
            breadcrumb: {
              text: 'ALI-2 Settings - Payment Settings'
            }
          },
          component: () => import('@/pages/Ali2Settings/PaymentSettings/paymentSettingsIndex.vue')
        },
        {
          path: '/package/:id/details',
          name: 'packageDetails',
          meta: {
            title: 'Package Details',
            breadcrumb: [
              {
                text: 'Orders',
                path: '/orders'
              },
              {
                text: 'Package Details'
              }
            ]
          },
          component: () => import('@/pages/PackageDetails/PackageDetails.vue')
        }
      ],
      meta: {
        requiresAuth: true,
        breadcrumb: {
          text: 'Dashboard',
          path: '/'
        }
      }
    },
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/Login/Login.vue'),
      meta: {
        requiresGuest: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.commit('changeBreadcrumbs', [])
  to.matched.map(record => {
    if (record.meta.breadcrumb) {
      if (Array.isArray(record.meta.breadcrumb)) {
        const breadcrumbs = record.meta.breadcrumb.slice(0)

        breadcrumbs.map((breadcrumb: any) => {
          if (!breadcrumb.path) {
            breadcrumb.path = record.path
          }

          store.commit('pushBreadcrumb', breadcrumb)
        })
      } else if (typeof record.meta.breadcrumb === 'object') {
        const breadcrumb = (<any>Object).assign({}, record.meta.breadcrumb)

        if (!breadcrumb.path) {
          breadcrumb.path = record.path
        }

        store.commit('pushBreadcrumb', breadcrumb)
      }
    }
  })

  if (to.matched.some(record => record.meta.title)) {
    store.commit('changePageTitle', to.meta.title)
  } else {
    store.commit('changePageTitle', '')
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!AuthService.loggedIn()) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (AuthService.loggedIn()) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
