export default {
  // Sidebar
  changeFormItem: (state: any, formItems: any) => {
    state.formItems = formItems
  },
  changeSidebarMiniVariant: (state: any, newValue: boolean) => {
    state.sidebarMiniVariant = newValue
  },

  changeDrawerState: (state: any, newValue: boolean) => {
    state.drawerState = newValue
  },

  // Breadcrumbs
  changeBreadcrumbs: (state: any, breadcrumbs: any) => {
    state.breadcrumbs = breadcrumbs
  },
  pushBreadcrumb: (state: any, breadcrumb: any) => {
    state.breadcrumbs.push(breadcrumb)
  },
  unshiftBreadcrumb: (state: any, breadcrumb: any) => {
    state.breadcrumbs.unshift(breadcrumb)
  },

  // Toolbar
  changePageTitle: (state: any, pageTitle: any) => {
    state.pageTitle = pageTitle
  },

  // Snackbar Mutations
  showSnackbar: (state: any, payload: any) => {
    state.snackbar.text = payload.text
    state.snackbar.multiline = payload.text.length > 100

    if (payload.multiline) state.snackbar.multiline = payload.multiline
    if (payload.timeout) state.snackbar.timeout = payload.timeout
    if (payload.color) state.snackbar.color = payload.color

    state.snackbar.visible = true
  },
  closeSnackbar: (state: any) => {
    state.snackbar.visible = false
    state.snackbar.multiline = false
    state.snackbar.timeout = 3000
    state.snackbar.color = ''
    state.snackbar.text = null
  },
  
  // Loading
  showLoading: (state: any, payload: any) => {
    state.loading.visible = true
    state.loading.text = payload.text
    if (payload.color) state.loading.color = payload.color
    if (payload.width) state.loading.width = payload.width
  },
  closeLoading: (state: any) => {
    state.loading.visible = false
    state.loading.text = null
    state.loading.color = 'white'
    state.loading.width = 0.2
  }
}
