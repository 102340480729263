import BaseService from '@/services/BaseService'
import config from '@/configs'
import router from '@/router'

const storage = window.localStorage

class AuthService extends BaseService {
  endPoint: any

  // constructor() {
  //   super()

  //   this.endPoint = 'oauth/token'
  // }

  // login(username: any, password: any) {
  //   return this.post({
  //     client_id: config.credentials.clientID,
  //     client_secret: config.credentials.clientSecret,
  //     grant_type: 'password',
  //     username,
  //     password
  //   })
  // }

  // loggedIn() {
  //   const authToken = storage.getItem('access_token')

  //   return typeof authToken == 'string' && authToken.length > 0
  // }

  // logout() {
  //   storage.clear()

  //   router.push('/login')
  // }

  constructor () {
    super()

    this.init()
  }

  userData () {
    return JSON.parse(storage.getItem('user_data'))
  }

  hasRole (checkedRole) {
  // hasRole () {
    if (this.userData() === null) {
      return false
    }

    return this.userData().roles.reduce((prev, role) => {
      if (prev === true || role.slug === checkedRole) {
        return true
      }

      return false
    }, false)
  }

  init () {
    this.endPoint = 'api/login'
  }

  login (username, password) {
    return this.post({
      client_id: config.credentials.clientID,
      client_secret: config.credentials.clientSecret,
      grant_type: 'password',
      username,
      password
    })
  }

  getUserData () {
    this.endPoint = 'api/me'
    const promise = this.get()

    this.init()

    return promise
  }

  loggedIn () {
    const authToken = storage.getItem('access_token')
    return typeof authToken === 'string' && authToken.length > 0
  }

  logout () {
    window.localStorage.clear()
    window.location.reload(true)
    router.push('/login')
  }
}

export default new AuthService()
