// import core modules
import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from 'vuetify'
import VeeValidate from 'vee-validate'
import colors from 'vuetify/es5/util/colors'
import './registerServiceWorker'

// import application modules
import App from './App.vue'
import router from '@/router'
import store from '@/store'

// use external plugins
Vue.use(Vuetify, {
  theme: {
    primary: '#da1a0c',
    secondary: colors.cyan.darken3,
    accent: colors.red.accent2,
    error: colors.red.base,
    info: colors.blue.base,
    warning: colors.amber.base,
    success: colors.purple.base
  }
})

Vue.use(VeeValidate, {
  dictionary: {
    en: {
      messages: {
        required: () => 'This field is required',
        not_in: () => 'This value is duplicated',
        alpha_num: () => 'This field may only contain alpha-numeric characters.',
        numeric: () => 'This field may only contain numeric characters.',
        decimal: (name, max) => `This field must be numeric and may contain ${max} decimal points.`,
        max_value: (name, max) => `This field cannot be greater than ${max}.`,
        min_value: (name, min) => `This field cannot be less than ${min}.`
      }
    }
  }
})

// config overrides
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
